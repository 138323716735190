/* Typography */

body {
	font-size: 0.937rem;
	font-family: $type1;
	font-weight: initial;
	line-height: normal;
	-webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	font-weight: normal;
	line-height: normal;
}

p {
	font-size: $default-font-size;
	margin-bottom: .5rem;
	line-height: 1.5rem;
}

h1,
.h1 {
	font-size: 3.125rem;
}

h2,
.h2 {
	font-size: 2.312rem;
}

h3,
.h3 {
	font-size: 2.187rem;
	@media (max-width: 1024px) {
		font-size: 2rem;
	}
	@media (max-width: 991px) {
		font-size: 1.5rem;
	}
}

h4,
.h4 {
	font-size: 1.875rem;
}

h5,
.h5 {
	font-size: 1.25rem;
	@media (max-width: 1024px) {
		font-size: 1rem;
	}
}

h6,
.h6 {
	font-size: 1.125rem;
}

.display-1 {
	font-size: 3.75rem;
	@media (max-width: 991px) {
		font-size: 3rem;
	}
}

.display-2 {
	font-size: 3.125rem;
	@media (max-width: 991px) {
		font-size: 2.5rem;
	}
}

.display-3 {
	font-size: 2.5rem;
	@media (max-width: 991px) {
		font-size: 2rem;
	}
}

.display-4 {
	font-size: 1.875rem;
	@media (max-width: 991px) {
		font-size: 1.5rem;
	}
}

.display-5 {
	font-size: 1.25rem;
	@media (max-width: 991px) {
		font-size: 1rem;
	}
}

.font-weight-medium {
	font-weight: 500;
}

.text-small {
	font-size: 13px;
}