/* Forms */

.form-group {
  margin-bottom: 1.5rem;
}
.form-control {
  border: 1px solid $border-color;
  font-weight: 400;
  font-size: $input-font-size;
  height: auto;
}

