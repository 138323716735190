.footer {
	color: $footer-color;
	background: theme-color(primary) url(../images/footer_bg.svg);
	background-size: cover;
	background-position: left bottom;
	p {
		margin-bottom: 0.25rem;
	}
	.footer-link {
		color: $footer-color;
	}
	.footer-top {
		padding: 74px 0;
		.footer-title {
			font-size: 1rem;
			font-weight: 500;
			margin-bottom: .875rem;
		}
		.social-icons {
			margin-top: 1.25rem;
			a {
				i {
					font-size: 2rem;
					color: $white;
					margin-right: 1rem;
					-webkit-transition: all 0.3s;
					-moz-transition: all 0.3s;
					-ms-transition: all 0.3s;
					-o-transition: all 0.3s;
					transition: all 0.3s;
				}
				&:hover {
					i {
						color: theme-color(dark);
					}
				}
			}
		}
	}
	.footer-bottom {
		background: $footer-bottom-bg;
		padding: 30px 0;
		border-top: 1px solid $footer-border;
		img {
			width: 200px;
		}
	}
	.list-footer {
		list-style: none;
		padding-left: 0;
		li {
			a {
				display: block;
				padding: .625rem 0;
				color: $white;
				font-size: 14px;
				color: $footer-color;
			}
		}
	}
}