/*------------------------------------------------------------------
 [Master Stylesheet]

  Project:	Marshmello Admin
  Version:	1.0.0
-------------------------------------------------------------------*/



/*-------------------------------------------------------------------
  ===== Table of Contents =====

  * Bootstrap functions
  * Template variables
  * SCSS Compass Functions
  * Boostrap Main SCSS
  * Core Styles
    + Reset Styles
    + Fonts
    + Navbar
    + Typography
    + Footer
  * Components
    + Buttons
    + Checkboxes and Radios
    + Forms
    + owl carousel
    + jquery flipster
-------------------------------------------------------------------*/


/*-------------------------------------------------------------------*/
/* === Import Bootstrap functions and variables === */
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

/*-------------------------------------------------------------------*/
/* === Import template variables === */
@import "variables";

/*-------------------------------------------------------------------*/
/* === SCSS Compass Functions === */
@import "../node_modules/compass-mixins/lib/compass";
@import "../node_modules/compass-mixins/lib/animate";

/*-------------------------------------------------------------------*/
/* === Boostrap Main SCSS === */
@import "../node_modules/bootstrap/scss/bootstrap";

/*-------------------------------------------------------------------*/

/*-------------------------------------------------------------------*/
/* === Core Styles === */
@import "reset";
@import "fonts";
@import "typography";
@import "footer";
@import "navbar";
@import "main";
@import "testimonial";
@import "pricing";

/*-------------------------------------------------------------------*/
/* === Components === */
@import "components/buttons";
@import "components/forms";
@import "components/toggle-switch";
@import "components/owl-carousel";
@import "components/flipster";

