.owl-carousel {
	.owl-item {
		.item {
			border-radius: 19.5px;
			overflow: hidden;
			img {
				display: block;
				width: 100%;
				box-shadow: 0 2px 10px 0 rgba(203, 203, 203, 0.44);
				border: solid 10px $white;
				border-radius: 19.5px;
				-webkit-transition: all 1s;
				-moz-transition: all 1s;
				-ms-transition: all 1s;
				-o-transition: all 1s;
				transition: all 1s;
			}
			&:hover {
				img {
					transform: scale(1.1);
				}
			}
		}
		border-radius: 19.5px;
		
	}
}

.owl-theme {
	.owl-nav {
		&.disabled {
			+.owl-dots {
				margin-top: 70px;
				@media (max-width: 991px) {
					margin-top: 10px;
				}
			}
		}
	}
	.owl-dots {
		margin-top: 70px;
		.owl-dot {
			span {
				width: 18px;
				height: 18px;
				margin: 5px 7px;
				background: $white;
				display: block;
				-webkit-backface-visibility: visible;
				transition: opacity .2s ease;
				border-radius: 3;
				border: solid 2px theme-color(primary);
			}
			&.active,
			&:hover {
				span {
					background: theme-color(primary);
				}
			}
		}
	}
	&.owl-carousel-testimonial {
		.owl-dots {
			text-align: left;
			.owl-dot {
				span {
					width: 10px;
					height: 10px;
					margin: 5px 7px;
					background: transparent;
					border: 1px solid $testimonial-nav-color;
				}
				&.active,
				&:hover {
					span {
						background: $testimonial-nav-color;
					}
				}
			}
		}
	}
}