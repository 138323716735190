/* Toggle Switch */

.toggle-switch {
	position: relative;
	display: inline-block;
	width: 45px;
	height: 23px;
	.toggle-slider {
		position: absolute;
		cursor: pointer;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: $toggle-switch-bg;
		-webkit-transition: .4s;
		transition: .4s;
		border-radius: 34px;
		border: 1px solid $border-color;
		&:before {
			position: absolute;
			content: "";
			height: 18px;
			width: 18px;
			left: 2px;
			bottom: 2px;
			background-color: $white;
			-webkit-transition: .4s;
			transition: .4s;
			border-radius: 50%;
		}
	}
	input {
		visibility: hidden;
		&:checked {
			+.toggle-slider {
				background-color: theme-color(primary);
				&:before {
					-webkit-transform: translateX(21px);
					-ms-transform: translateX(21px);
					transform: translateX(21px);
				}
			}
		}
	}
}
.text-active {
	color: theme-color(dark);
}

/* Rounded sliders */